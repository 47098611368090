@keyframes exit {
  0% {
   /* transform: scale(.1); */
    /* transform: scale(1); */
    text-shadow: none ;
  }
  20% {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0;
  }
}

@keyframes enter {
  0% {
    opacity: 0;
    transform: scale(0.5);
    transform-origin: center;
  }

  80% {
    opacity: 1;
    transform: scale(1);
    /* text-shadow: 1px 0px 12px #fffdfd; */
  }
  100% {
    opacity: 1;
    /* text-shadow: 1px 0px 12px #fffdfd; */
  }
}
.hidden-slide{
  opacity: 0 !important;
  visibility: hidden;
}
.next-slide {
  
  animation: exit 2s ease-out;
  /* transition: all 1s ease-in; */
  animation-fill-mode: forwards;

  /* transform: scale(0.1); */
}
.last-slide {
  
  animation: exit 2s ease-in;

  /* transition: all 1s ease-in;
  transform: scale(0.1); */
  animation-fill-mode: forwards;
}
.current-slide {
  opacity: 0;
  /* transform-origin: center; */
  animation: enter 1s ease-out;
  animation-fill-mode: forwards;
  animation-delay: .5s;
  /* transition: all .5s ease-in; */
  /* transform: scale(1) !important; */
  /* transition-delay: 1s; */
}
.add-delay div:nth-child(1){
  animation-delay: .5s !important;

}
.add-delay div:nth-child(2){
  animation-delay: .9s !important;

}
.add-delay div:nth-child(3){
  animation-delay: 1.2s !important;

}
.add-delay div:nth-child(4){
  animation-delay: 1.6s !important;

}
.add-delay div:nth-child(5){
  animation-delay: 2s !important;

}
.add-delay div:nth-child(6){
  animation-delay: 2.5s !important;

}
.add-delay div:nth-child(7){
  animation-delay: 2.9s !important;

}
.add-delay div:nth-child(8){
  animation-delay: 3.3s !important;

}
.add-delay div:nth-child(9){
  animation-delay: 3.7s !important;

}
.add-delay div:nth-child(10){
  animation-delay: 4.1s !important;

}
.add-delay div:nth-child(11){
  animation-delay: 4.5s !important;

}

  .add-delay div:nth-child(12){
  animation-delay: 4.9s !important;

}
.add-delay div:nth-child(13){
  animation-delay: 5.3s !important;

}
.add-delay div:nth-child(14){
  animation-delay: 5.7s !important;

}
.add-delay div:nth-child(15){
  animation-delay: 6.1s !important;

}
.add-delay div:nth-child(16){
  animation-delay: 6.5s !important;

}
.add-delay div:nth-child(17){
  animation-delay: 6.9s !important;

}
.add-delay div:nth-child(18){
  animation-delay: 7.3s !important;

}

.inview-div{
  display: inline !important;
  margin: 0 !important;
  
}
.aos-animate{
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}
.hidden-placeholder{
  opacity: 0 !important;
}
