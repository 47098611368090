:root {
  --primary: #145265;
  --pprimary: #f2f2f2;
  --bgprimary: #145265a1;
  --bgsecondary: #001e24bd;
  --secondary: #ffc000;
  --tprimary: rgb(218, 237, 242, 1);
  --tsecondary: #005a6b;
  --lprimary: #145265e6;
  --psecondary: #648c94;
}

html {
  /* scroll-snap-type: y mandatory; */
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}
body {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  overflow-x: hidden;
}
div
  nav
  p
  h1
  h2
  h3
  h4
  h5
  iframe
  video
  li
  ul
  section
  button
  input
  label
  span
  i
  svg {
  overflow: hidden;
}
.limit-scroll {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.no-margined {
  background: transparent;
}
.margined-body {
  animation: backgroundColor 500ms;
  animation-fill-mode: forwards;
  color: rgb(0, 0, 0, 0.5);
  z-index: 1999 !important;
  position: fixed;
  height: 100%;
  width: 100%;
}
.list-icon {
  margin-right: 1rem;
  color: white;
  cursor: pointer;
}

.animate-menu li {
  animation: listin 300ms;
  opacity: 0;
  animation-fill-mode: forwards;
}
.animate-menu li:nth-child(1) {
  animation-delay: 100ms;
}
.animate-menu li:nth-child(2) {
  animation-delay: 150ms;
}
.animate-menu li:nth-child(3) {
  animation-delay: 200ms;
}
.animate-menu li:nth-child(4) {
  animation-delay: 250ms;
}
.animate-menu li:nth-child(5) {
  animation-delay: 300ms;
}
@keyframes listin {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.close-icon-menu {
  color: white;
  position: fixed;
  top: 3%;
}
.word-title-parent {
  position: absolute;
  margin: 4rem 2rem;
}
.word-div-mobile {
  display: inline-block !important;
  color: white;
  opacity: 0;
  animation: sequence 1s;
  animation-fill-mode: forwards;
  margin: 0rem 0.2rem;
  font-size: 1.5rem;
}
@keyframes sequence {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrap-div-home {
  scroll-snap-type: y mandatory;
}
.child {
  scroll-snap-align: start;
}
.mobile-text {
  vertical-align: center !important;
  margin-top: 5rem;
}
.mobile-text h3:nth-child(1) {
  animation: 1.2s !important;
  animation-fill-mode: forwards !important;
}
.mobile-text h3:nth-child(2) {
  animation: 1.6s !important;
}
.mobile-text h3:nth-child(3) {
  animation: 1.9s !important;
}
.mobile-text h3 {
  opacity: 0;
  color: white;
  animation: fadeInText 1s;
  animation-fill-mode: forwards;
  /* animation-delay: 1s; */
}
@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.home-1 {
  height: 100vh;
  opacity: 1;
  position: relative;
  z-index: 199;
}
.long-video {
  height: 200vh;
}
.service-1 {
  height: 80vh;
  opacity: 1;
  position: relative;
  z-index: 199;
}
.works-vid-parent {
  z-index: 10;
  height: 60vh;
  overflow: hidden;
  position: relative;
}
.slider-home-text {
  overflow: visible !important;
  width: 100% !important;
}
.home-video {
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.3;
  object-fit: cover;
  /* Safari-specific adjustments */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.service-video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-height: 100%;
  width: 100%;
  height: auto;
  z-index: -1;
  opacity: 0.2;
  object-fit: cover;
  overflow: hidden;
}

.mediabuying-video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-height: 100%;
  width: 100%;
  height: auto;
  z-index: -1;
  opacity: 0.1;
  object-fit: cover;
  overflow: hidden;
}
.video-div {
  height: 100% !important;
}

.menu-list {
  font-weight: 300;
}
.vid-layer {
  background-color: rgba(20, 82, 101, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.home-layer {
  background-color: rgba(20, 82, 101, 1);
  opacity: 1;
  z-index: 2;
}
.service-layer {
  background-color: rgba(20, 82, 101, 1);
}

.mediabuying-layer {
  background-color: rgba(20, 82, 101, 1);
}
.nav-button {
  padding: 0.8rem 0.5rem !important;
  color: black;
  background-color: var(--secondary);

  /* margin-right: 0.5rem; */
  opacity: 1;
  transition: opacity 150ms ease-in-out;
  border: 0.5px solid #006f83;
  z-index: 197;
}
.services-link {
  text-decoration: none !important;
}
.services-link:hover {
  outline: none !important;
  text-decoration: none !important;
}
.hide-button {
  /* padding: 0.8rem 1rem !important;
  color: black;
  background-color: #ffc000;
  margin-right: 0.5rem;
  opacity: 0;
display: none;  border: 0.5px solid #006f83;
  z-index: 197;

  transition: opacity 150ms ease-in-out; */
  display: none;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin: 30px;
  color: white;
  gap: 20px;
}

.pagination-active {
  color: #ffc000;
}
.pagination-page-item {
  transition: all 200ms;
}

.pagination-page-item:hover {
  scale: 1.1;
}
.pagination-active:hover {
  scale: 1.1;
}
.logo-nav {
  display: none !important;
}
.logo-footer {
  display: block !important;
  width: 3rem;
  margin-left: 1rem;
  fill: white;
}
.hide-nav {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.show-nav {
  opacity: 1;
  visibility: visible;
}
.navbar-main {
  background: rgb(20, 82, 101, 1);
}
.title-text-row {
  position: absolute;
  top: 20%;
  left: 5%;
  right: 10%;
  z-index: 198;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
}
.title-text-row-home {
  position: absolute;
  top: 10%;
  left: 5%;
  right: 10%;
  text-align: center;
}
.intro-text {
  text-shadow: rgba(0, 0, 0, 1);
  color: white;
  line-height: 3rem;
  font-weight: 300;
  text-align: center;
  animation: textup 500ms;
  opacity: 0;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
}
.service-paragraph {
  animation: serviceParagraph 1s;
  animation-delay: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
  color: var(--pprimary);
  font-size: 2rem;
  font-weight: 100;
  text-align: center;
}
.mobile-paragraph {
  color: var(--pprimary);
  font-size: 1.5rem;
  font-weight: 100;
  text-align: center;
  line-height: 1rem;
}
@keyframes serviceParagraph {
  0% {
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.intro-text-home {
  /* text-shadow: 1px 1px rgba(0, 0, 0, 1); */
  text-align: center !important;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--tprimary);
  /* background-color: #001e2400; */
  line-height: 2.1rem;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  /* animation: textup 1s; */
  /* opacity: 0; */
  animation-fill-mode: forwards;
  transform: translateY(-50%, -50%);
  /* animation-delay: 800ms; */
  z-index: 8;
}
.intro-delayed {
  animation-delay: 3.5s;
}

@keyframes textup {
  0% {
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.explore-row {
  position: absolute;
  bottom: 0;
  left: 40%;
}
.sidebar-hide {
  position: fixed;
  top: 0;
  left: -100vw;
  width: 13rem;
  transition-duration: 1s;
  height: 100%;
  background: #001e24;
  height: 100%;
  z-index: 9999;
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.sidebar-show {
  /* transform: translateX(-200px); */
  width: 13rem;
  transition-duration: 200ms;
  /* animation: sidebarShow 500ms; */
  animation-fill-mode: forwards;
  /* transition: left 50ms linear; */
  background: #001e24;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
@keyframes sidebarShow {
  0% {
  }
  100% {
    transform: translateX(0);
  }
}
.close-span {
  font-size: 2.8rem;
  color: white;
  cursor: pointer;
  display: inline;

  font-weight: 200;
  top: 0;
}

.explore {
  color: var(--secondary);
  font-size: larger;
  font-weight: 300;
  cursor: pointer;
}
.explore-hover {
  transition-duration: 300ms;
  cursor: pointer;
}
.explore-hover:hover {
  transform: scale(1.3);
}
.explore-icon {
  cursor: pointer;
  transform: rotate(90deg);
  margin-left: 1.6rem;
  width: 15px;
  fill: var(--secondary) !important;
}

.section-2 {
  background: #001e24;
  height: auto;
}
.textTitle-2 {
  color: var(--tprimary);
  font-weight: 300;
  font-size: 2rem;
}
.latest-works-column {
  margin-top: 2rem;
}

.nav-link {
  color: white !important;
  padding: 0.25rem 0rem;
  transition-duration: 200ms;
}
.nav-link:after {
  color: white !important;
  padding: 0.25rem 0rem;
}
.nav-link:hover {
  color: #f3bc06 !important;
  transform: scale(1.2);
  margin-left: 1rem;
}

.footer-li-hover:hover {
  text-decoration: none;
  color: #f3bc06 !important;
  transition-duration: 200ms;
}
.footer-navigation {
  color: var(--tprimary);
  text-decoration: none;
}
.footer-navigation:hover {
  color: var(--secondary);
  text-decoration: none;
}
.logo-svg-footer svg {
  width: 3rem;
  margin-left: 1rem;
  fill: white !important;
}
.footer-div {
  background: #145265;
  overflow: hidden;
}
.phone-svg svg {
  padding-right: 0 !important;
  width: 13px;
  /* margin-left: .5rem; */
}
.logo {
  width: 4rem;
}
.our-services {
  color: var(--tprimary);
  font-weight: 400;
  font-size: 2rem;
}
.our-services-title {
  color: #145265;
  font-weight: 600;
  font-size: 2rem;
}
.mobile-icon {
  font-size: 1.6rem;
  display: inline-block;
  position: relative;
  top: 0.1rem;
}
.service-column-size {
  font-weight: 300;
  margin-bottom: 6rem;
}
.e-icon {
  font-size: 1rem;
  display: inline-block;
  position: relative;
  top: 0.1rem;
  right: 0.1rem;
}
.moto {
  font-size: small;
}

#icons-menu li {
  display: inline;
  padding-right: 2rem;
  font-size: 1rem;
}
#icons-menu li a {
  color: white;
}
#icons-menu li a:hover {
  color: #f3bc06;
}
.map-p {
  margin-left: 1.7rem;
}

.site-map {
  display: none;
}
.site-map-menu {
  padding-left: 1rem;
  font-weight: 300;
}
.column-footer {
  color: #daedf2;
  font-size: 0.75rem;
}
.gps-col {
  padding-left: 0.7rem !important;
}
.gps-col svg {
  margin-top: 0.6rem;
  padding-right: 0 !important;
  width: 15px;
}

.site-map-menu li {
  line-height: 1.5rem;
  font-size: 0.75rem;
  list-style-type: none;

  color: #daedf2;
}
.site-map-menu li:hover {
  text-decoration: none !important;
  outline: none !important;
  color: #f3bc06 !important;
  transition-duration: 200ms;
}
.site-map-menu li::before {
  content: "\2022";
  color: #f3bc06;
  font-size: 2rem;
  margin-left: -1rem;
  margin-right: 0.5rem;
  display: inline-block;
  width: 1rem;
  position: relative;
  top: 0.4rem;
}
.services-section {
  position: relative;
  background: rgba(0, 30, 36, 0.8);
  color: white;
  height: 100% !important;
}
.services-row-column-2 {
  margin-top: 5rem;
}
.tns-outer > button {
  display: none !important;
}
.our-clients-home {
  margin-top: 2rem;
  font-weight: 400;
  font-size: 2rem;
  color: var(--tsecondary);
}
.media-img-slider {
  width: auto;
  height: 65px;
  object-fit: fill;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.media-div-parent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.media-buying {
  background-color: #daedf2;
}
.monitoring-none {
  height: 392px;
}

.li-media {
  opacity: 0;
  visibility: hidden;
  animation-fill-mode: forwards;
}
/* .aos-animate > li */
.media-buying-info li {
  position: relative;
  animation: textin 500ms;
  opacity: 0;

  animation-fill-mode: forwards;
  color: var(--primary);
}
.media-buying-info :nth-child(1) {
  animation-delay: 1s !important;
}
.media-buying-info :nth-child(2) {
  animation-delay: 1.5s !important;
}
.media-buying-info :nth-child(3) {
  animation-delay: 2s !important;
}
.hidden-li-monitoring {
  visibility: hidden !important;
}

@keyframes textin {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.network-header {
  font-weight: 500;
  color: #006f83;
  font-size: 1.5rem;
}

.work-img {
  border-radius: 0.5rem !important;
  z-index: 1;
}
.works-title {
  color: white !important;
  font-size: 1.5rem;
  position: relative;
  bottom: 4.5rem;
  z-index: 151;
  font-weight: 300 !important;
}
.work-col-home {
  position: relative;
  opacity: 0;
  animation: colin 1.5s;
  animation-fill-mode: forwards;
}
@keyframes colin {
  0% {
    opacity: 0;
    right: -200px;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
}
.works-none {
  opacity: 0;
}
.animate-works-home {
  position: relative;
  opacity: 0;
  animation: worksup 500ms;
  animation-fill-mode: forwards;
}
@keyframes worksup {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.service-col-title {
  font-size: 1rem;
  opacity: 0;
}

.servicecol-descript {
  font-size: 0.9rem;
  white-space: pre-line;
  opacity: 0;
  word-wrap: break-word;
}

.seemore-col {
  color: var(--secondary);
  outline: none !important;
  text-decoration: none !important;
}

.seemore-col-icon {
  outline: none !important;
  text-decoration: none !important;
  color: var(--secondary);
}
.seemore-hover {
  display: flex;
  align-items: center;
  transition-duration: 200ms;
}
.seemore-hover:hover {
  outline: none !important;
  text-decoration: none !important;
  transform: scale(1.2);
}

.animate-services {
  display: block;
  position: relative;
  animation-fill-mode: forwards;
}
@keyframes servicesup {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-services svg {
  fill: transparent;
}
.about-overlay {
  background-color: rgb(20, 82, 101, 0.8);
  z-index: 2;
}

.about-section-1 {
  position: relative;
  height: 100vh;
  /* background-color: rgb(20, 82, 101); */
  /* background-image: url("./img/about.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  z-index: 1;
}
.about-section-1:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(20, 82, 101, 0.8);
}
.hide-columns {
  visibility: hidden;
}
.animate-columns {
  position: relative;
  animation: colup 300ms;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes colup {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.p-position-about {
  margin-top: 3rem;
}

.h-about-high {
  font-weight: 400;
  animation: about 3s;
  opacity: 0;
  animation-fill-mode: forwards;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-size: 2rem;
  z-index: 4;
}
.h-about-high-num {
  font-weight: 400;
  opacity: 0;
  animation: about 3s;
  animation-fill-mode: forwards;
  font-size: 4rem;
  z-index: 4;
}
.h-about-low {
  text-shadow: 2px black;
  position: relative;
  font-weight: 700;
  animation: und 500ms ease-out;
  animation-delay: 500ms;
  opacity: 0;
  animation-fill-mode: forwards;
  color: var(--pprimary);
  font-size: 4rem;
}

.dot-div::after {
  content: ".";
  color: var(--secondary);
  /* animation: jumpDot 1s; */
}
@keyframes jumpDot {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0);
  }
}

.h-about-low-num {
  /* margin-left: -.8rem; */
  position: relative;
  font-weight: 300;
  animation: und 500ms ease-out;
  animation-delay: 500ms;
  opacity: 0;
  animation-fill-mode: forwards;
  color: var(--pprimary);
  font-size: 1.4rem;
}
.h-about-high-2 {
  font-weight: 400;
  color: var(--primary);
}
.h-about-low-2 {
  font-weight: 300;
  color: var(--psecondary);
}
@keyframes about {
  0% {
  }
  100% {
    opacity: 1;
  }
}
@keyframes und {
  0% {
    left: -300px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
.about-img {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("./img/background.png");
  background-color: rgb(20, 82, 101, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  z-index: -1;
}
@keyframes imgin {
  0% {
    opacity: 0;
  }
  /* 25%{
    
    opacity: .25;
  }
  50%{
    
    opacity: .5;
  }
  75%{
    
    opacity: .75;
  } */
  100% {
    opacity: 1;
  }
}
.whoarewe-p {
  color: var(--psecondary);
  font-weight: 300;
}
.hidden-p {
  opacity: 0;
}
.animate-p {
  position: relative;
  animation: pup 1s;
  animation-fill-mode: forwards;
}
@keyframes pup {
  0% {
    transform: translateY(200px);
  }
  100% {
    opacity: 1 !important;
    transform: translateY(0);
  }
}
.client-img {
  max-width: 4rem;
  border-radius: 2rem;
  background-color: #006f83;
  outline: none !important;
}
.qoute-icon {
  color: #effafd;
}
.container-style {
  width: 290% !important;
}
.our-works-section2 {
  background: rgb(0, 30, 36);
  background: linear-gradient(
    0deg,
    rgba(0, 30, 36, 1) 88%,
    rgba(1, 62, 74, 1) 100%,
    rgba(20, 82, 101, 1) 100%
  );
}
.works-buttons {
  background-color: #02242b;
  color: white;
  padding: 0.5rem 0rem;
  border: none !important;
  border-radius: 1rem;
  margin: 1rem 0rem;
  font-weight: 300;
  outline: none !important;
  opacity: 1;
  animation-fill-mode: forwards;
  font-size: 0.9rem;
}
.selected-button {
  background-color: #ffc000;
  color: white;
  padding: 0.5rem 0.2rem;
  border: none !important;
  border-radius: 1rem;
  margin: 1rem 0rem;
  font-weight: 300;
  outline: none !important;
  font-size: 0.9rem;
}
.animate-button {
  position: relative;
  animation: buttonup 500ms;
  color: white;
  padding: 0.5rem 1rem;
  border: none !important;
  border-radius: 1rem;
  margin: 1rem 0.2rem;
  font-weight: 300;
  outline: none !important;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes buttonup {
  0% {
    opacity: 0;
    bottom: -200px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
.works-buttons:hover {
  background-color: #ffc000;
  transition: background-color 500ms;
}
.project-div {
  background: #001e24;
  height: 100%;
  /* width: 40%; */
  position: fixed;

  top: 0;
  z-index: 999;
  right: 0;
  opacity: 1;
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.3);
  /* animation: fadeinout 500ms linear 1 forwards; */
  color: white;
  overflow: scroll;
  transition: all 0.6s ease-out;
  /* animation: translateIn 1.3s; */
  /* animation-fill-mode: forwards; */
}
.hide-project {
  visibility: hidden;
  position: fixed;
  top: 0;
  background: #001e24;
  height: 100%;
  /* width: 40%; */
  position: fixed;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: 999;
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.3);
  color: white;
  overflow: scroll;
  transition: all 0.6s ease-out;
}
@keyframes tranlateIn {
  0% {
    right: -100vh;
  }

  100% {
    right: 0;
  }
}

.form-control-sm {
  background-color: #003742 !important;
  border: none;
  color: white !important;
  display: inline !important;
}
.form-control {
  background-color: #003742 !important;
  color: white !important;
  border: none !important;
  font-weight: 300;
}
.error-message {
  color: red;
  font-size: small;
  /* animation: fadeoutText 3s ; */
}
.sent-request {
  color: green;
  font-size: large;
  font-weight: 400;
  /* animation: fadeoutText 3s ; */
}
/* @keyframes fadeoutText{
  0% {
    opacity: 1;

  }
  100% {
    opacity: 0;
  }
} */
label {
  font-weight: 300;
  margin: 0.5rem 0rem;
  font-size: 0.8rem;
}
.project-hide {
  display: none;
}
.hide-overflow {
  overflow: hidden !important;
}
.submit-message {
  font-size: 2rem;
  color: white;
  text-align: center;
  transform: translateY(100%);
  margin: 0rem 1rem;
}
.close-button {
  position: absolute;
  font-size: 1.3rem;
  bottom: 30%;
  left: 42%;
  color: #f3bc06;
  font-weight: 300;
  background: transparent;
  border: none !important;
  outline: none !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.submited-form-div {
  width: auto;
  height: 100%;
  background-color: var(--primary);
  animation: fadeIN 3s;
  animation-fill-mode: forwards;
}
@keyframes fadeIN {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.dropdown-input {
  display: inline !important;
}
.submit-button {
  background-color: #145265;
  border: none;
  color: white;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: 300;
  font-size: smaller;
  width: 100%;
  outline: none !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white !important;
  font-weight: 200;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: white !important;
  font-weight: 200;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
  font-weight: 200;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: white !important;
  font-weight: 200;
}
.option-form {
  color: white;
}
.whoarewe-p {
  line-height: 1.3rem;
  font-size: 0.9rem;
}
/* .tns-slider{
    transition-timing-function: linear !important ;
    animation: slideShow 12 linear infinite !important;
} */
.slick-list {
  overflow: visible !important;
}
.slick-next::before {
  display: none !important;
}
.slider-images-parent {
  /* padding: 3rem 0rem; */
  /* display: inline-block; */
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-align: center;
  margin: 0rem 0rem;
}
.slider-img {
  /* display: inline-block;
  
  width: 110px;
  height: auto;
  vertical-align: middle; */
  width: 135px;
  height: 100px;
  margin: 1.8rem 0rem !important;
  margin-right: 0 !important;
  object-fit: contain;
}
@keyframes slideShow {
  0% {
  }
  100% {
    transform: translateX(-66.6666%) !important ;
  }
}
/*slider page about*/
.react-multiple-carousel__arrow {
  top: 3.5rem;
  background: none !important;
  opacity: 1 !important;
  color: black !important;
  outline: none !important;
}
.react-multiple-carousel__arrow::before {
  color: black !important;
}
.react-multiple-carousel__arrow--left {
  left: 0rem !important;
}
.react-multiple-carousel__arrow--right {
  right: 0 !important;
}
.embed-responsive {
  z-index: 140 !important;
}
.iframe-hidden {
  display: none !important;
}
.video-spinner {
  color: black !important;
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 999;
}
.mute-svg {
  cursor: pointer;
  max-width: 2rem;
  fill: white;
  display: block;
  position: absolute;
  z-index: 300;
  fill: white;
  top: 5%;
  left: 90%;
}
.audio-svg {
  cursor: pointer;
  max-width: 2rem;
  fill: white;
  display: block;
  position: absolute;
  z-index: 300;
  fill: white;
  top: 5%;
  left: 90%;
}
.iframe {
  z-index: 140 !important;
  border-radius: 10px !important;
  transform: scale(1.5) !important;

  /* -moz-box-shadow:    inset 0 0 10px #000000;
   -webkit-box-shadow: inset 0 0 10px #000000;
   box-shadow:         inset 0 0 10px #000000; */
  /* background: linear-gradient(transparent, rgba(0,0,0,0.4)) ; */
}
.scale-iframe {
  transform: scale(1.05);
  transition-duration: 500ms;
  border-radius: 10px;
}
.parent-iframe {
  border-radius: 10px;
}
.iframe-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgb(0, 30, 36);
  background: linear-gradient(
    0deg,
    rgba(0, 30, 36, 0.9864320728291317) 15%,
    rgba(61, 100, 111, 0.011642156862745057) 60%
  );
  overflow-x: hidden;
}
.show-descript-service {
  opacity: 0;
  animation: scaleTitle 1.8s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.show-title-service {
  opacity: 0;
  animation: scaleTitle 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
@keyframes scaleTitle {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.parent-svg svg {
  width: 50px !important;
}
@keyframes showParent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.parent-svg svg path {
  opacity: 0;
  animation: showsvg 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  fill: transparent;
  stroke: var(--lprimary);
}
@keyframes showsvg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader-svg-hide {
  display: none;
}
.opening-div {
  display: block;
  background: url("./svgs/intro.svg");
  object-fit: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  background-size: cover;
  object-fit: cover;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  /* Force Safari to recognize the stacking context */
  transform: translateZ(0);

  /* animation-delay: 3s; */
}
.animate-opening-div {
  display: block;
  background: url("./svgs/intro.svg");
  object-fit: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  background-size: cover;
  object-fit: cover;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  animation: layerup 0.8s ease-in;
  animation-fill-mode: forwards;
  /* Force Safari to recognize the stacking context */
  transform: translateZ(0);
}
@keyframes layerup {
  0% {
    top: 0;
  }
  100% {
    top: -100vh;
  }
}
.loader {
  position: fixed;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  /* animation: loaderUp 500ms;
  animation-delay: 2s !important; */
  animation-fill-mode: forwards;
  z-index: 999999 !important;
  /* animation-delay: 1s; */
  background: #001e24;
  /* Force Safari to recognize the stacking context */
  transform: translateZ(0);
}

/* .mask {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex !important;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
} */

@keyframes loaderUp {
  0% {
  }

  100% {
    opacity: 0;

    /* transform: translateY(-1000px); */
  }
}
.fixed-top {
  z-index: 197 !important;
}

.spinner {
  position: absolute;
  top: 50vh;
  left: 45vw;
  z-index: 99999999 !important;
}

.opening-svg {
  z-index: 200;
  width: 100%;
  height: 100%;
  position: fixed;
  display: block !important;
  margin: auto !important;
  opacity: 1;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  align-self: center;
  justify-self: center;
}
.media-buying-svg {
  width: 100px !important;
  fill: var(--primary);
}
.no-svg {
  width: 50px;
  height: 47.89px;
}

.svg-col svg {
  width: 50px;
  fill: transparent;
  stroke: var(--tprimary) !important;
}
.svg-col svg path {
  width: 50px;
  fill: transparent;
  stroke: var(--tprimary) !important;
  animation: createSvg 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes createSvg {
  0% {
  }
  100% {
    opacity: 1;
  }
}
.svgs-title-home {
  font-weight: 300;
  margin-top: 2rem;
  color: var(--tprimary);
  animation: scaleText 3s;
  opacity: 0;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes scaleText {
  0% {
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: scale(0);
  }
}
.mediabuying-1 {
  height: 70vh;
  opacity: 1;
  position: relative;
  z-index: 199;
}
@keyframes titleUp {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0);
  }
}
.hidden-service {
  opacity: 0;
}

/* overflow of slider */
/* .tns-ovh {
  overflow: visible !important;
} */

@media (max-width: 400px) {
}
@media (min-width: 410px) {
}
@media (min-width: 600px) {
  .pagination {
    font-size: 1.4rem;
  }
}

@media (min-width: 767px) {
  .project-div {
    background: #001e24;
    height: 100%;
    /* width: 40%; */
    position: fixed;

    top: 0;
    z-index: 999;
    right: 0;
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.3);
    /* animation: fadeinout 500ms linear 1 forwards; */
    color: white;
    overflow: scroll;
    transition: all 0.8s ease-out;
    /* animation: translateIn 1.3s; */
    /* animation-fill-mode: forwards; */
  }
  .hide-project {
    visibility: hidden;
    position: fixed;
    top: 0;
    background: #001e24;
    height: 100%;
    /* width: 40%; */
    position: fixed;
    right: -100vw;
    top: 0;
    z-index: 999;
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.3);
    color: white;
    overflow: scroll;
    transition: all 1.3s ease-out;
  }
  .intro-vid {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-top: -2rem !important;
    /* Safari-specific adjustments */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .whoarewe-p {
    line-height: none !important ;
    font-size: none !important;
  }
  .mediabuying-1 {
    height: 80vh;
  }
  .works-vid-parent {
    height: 80vh;
  }
  .parent-svg svg {
    width: 50px !important;
  }
  .parent-svg svg path {
    animation: animateSvgsIpad 1s;
    opacity: 1;
    animation-fill-mode: forwards;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    fill: transparent;
    stroke: var(--lprimary);
  }
  @keyframes animateSvgsIpad {
    0% {
      stroke-dasharray: 400;
      stroke-dashoffset: 400;
    }
    100% {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
    }
  }
  .svgs-title-home {
    font-weight: 300;
    margin-top: 2rem;
    color: var(--tprimary);
    animation: scaleText 3s;
    opacity: 0;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
  .works-buttons {
    background-color: #02242b;
    color: white;
    padding: 0.5rem 2rem;
    border: none !important;
    border-radius: 1rem;
    margin: 1rem 1rem;
    font-weight: 300;
    outline: none !important;
    opacity: 1;
    animation-fill-mode: forwards;
    font-size: 0.9rem;
  }
  .selected-button {
    background-color: #ffc000;
    color: white;
    padding: 0.5rem 2rem;
    border: none !important;
    border-radius: 1rem;
    margin: 1rem 1rem;
    font-weight: 300;
    outline: none !important;
    font-size: 0.9rem;
  }
  .animate-button {
    position: relative;
    animation: buttonup 500ms;
    color: white;
    padding: 0.5rem 2rem;
    border: none !important;
    border-radius: 1rem;
    margin: 1rem 1rem;
    font-weight: 300;
    outline: none !important;
    opacity: 0;
    animation-fill-mode: forwards;
  }
  .navbar-main {
    background-color: transparent !important;
  }
  .black-menu {
    color: var(--primary) !important ;
    fill: var(--primary) !important ;
  }
  .svg-col svg {
    width: 50px;
    fill: transparent;
    stroke: var(--tprimary) !important;
  }
  .svg-col svg path {
    width: 50px;
    fill: transparent;
    stroke: var(--tprimary) !important;
    animation: createSvg 2s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  @keyframes createSvg {
    0% {
      stroke-dasharray: 450;
      stroke-dashoffset: 450;
    }
    100% {
      opacity: 1;
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
    }
  }
  .nav-button {
    padding: 0.8rem 1rem !important;
    color: black;
    background-color: var(--secondary);

    margin-right: 0.5rem;
    opacity: 1;
    transition: opacity 150ms ease-in-out;
    border: 0.5px solid #006f83;
    z-index: 197;
  }
  .service-1 {
    height: 85vh;
    opacity: 1;
    position: relative;
    z-index: 199;
  }
  .latest-works-column {
    margin-top: 2rem;
  }
  .home-1 {
    height: 100vh;
    opacity: 1;
    position: relative;
    z-index: 199;
  }
  .intro-text-home {
    /* text-shadow: 1px 1px rgba(0, 0, 0, 1); */
    position: absolute;

    color: var(--tprimary);
    /* background-color: #001e2400; */
    line-height: 3rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center !important;

    margin-top: 0 !important;
    /* animation: textup 1s; */
    /* opacity: 0; */
    animation-fill-mode: forwards;
    transform: translateY(-50%, -50%);
    /* animation-delay: 800ms; */
    z-index: 8;
  }
  .section-service-page {
    height: auto;
  }
  .services-section {
    position: relative;
    background: rgba(0, 30, 36, 0.8);
    color: white;
    height: 512px !important;
  }
  .about-section-1 {
    padding-bottom: 4rem;
  }
  .numbers-row {
    margin-top: 8rem;
  }
  .numbers-col {
    position: relative;
    /* opacity: 0;
 animation: borderAnimation 1s;
 animation-fill-mode: forwards; */
    padding-bottom: 1rem !important;
  }
  .line-border {
    display: block;
    width: 15rem; /*or whatever width you want the effect of <hr>*/
    border-top: 2px solid var(--secondary);

    animation: lineDraw 1.5s ease-out;
    animation-fill-mode: forwards;
    z-index: 150;
    position: absolute;
    top: 100%;
    /* animation-delay: 2s; */
    /* left: -650px; */
    transform: translateY(650px);
    opacity: 0;
  }
  @keyframes lineDraw {
    100% {
      transform: translateY(0px);

      opacity: 1;
    }
  }
  /* .numbers-col::after{
  content: '';
  border-bottom: 2px solid var(--secondary);
   display: inline-block;
    height: 1rem;
    vertical-align: bottom;
    width: 100%;
 opacity: 0;
  animation: borderAnimation 1s;
 animation-fill-mode: forwards; 
} */
  @keyframes borderAnimation {
    0% {
    }
    100% {
      opacity: 1;
    }
  }
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999999 !important;
  }

  .logo-nav {
    display: block !important;
    width: 4.5rem;
    margin-left: 5rem;
    margin-top: -3.2rem;
    fill: white;
  }

  .before-card {
    margin-top: 0.5rem;
  }
  .react-multiple-carousel__arrow--left {
    left: -1rem !important;
    bottom: 1rem;
  }
  .react-multiple-carousel__arrow--right {
    right: -1rem !important;
    bottom: 1rem;
  }
  .h-about-low-num {
    margin-left: -0.8rem;
  }
  .services-row {
    margin-bottom: 6rem;
  }
  .site-map {
    display: block !important;
  }

  @keyframes backgroundColor {
    0% {
    }
    100% {
      background-color: rgb(0, 0, 0, 0.8);
    }
  }
  .title-text-row {
    top: 40%;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
  }
  .col-address {
    margin-left: -1.5rem !important;
  }

  .explore-row {
    left: 45%;
  }
  .sidebar {
    margin-left: -1rem;
  }
}

@media (min-width: 989px) {
  .intro-text-home {
    text-align: center !important;
    width: 100% !important;
    margin: 0rem 2rem;
  }
  .before-card {
    margin-left: -0.5rem;
    margin-top: 3rem;
  }
  .react-multiple-carousel__arrow--left {
    left: -1rem !important;
  }
  .react-multiple-carousel__arrow--right {
    right: -1rem !important;
  }
  .service-paragraph {
    margin: 0rem 13rem;
  }

  .col-address {
    margin-left: 0 !important;
  }
  .sidebar {
    margin-left: -2.1rem;
  }
  /* .services-row-column {
    margin-left: 5rem;
  }
  .services-row-column-2 {
    margin-left: 5rem;
  } */

  .project-div {
    width: 40%;
  }
}
@media (min-width: 1020px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 350, 250);
  }
}
@media (min-width: 1200px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 480, 150);
  }
  .about-section-1 {
    height: 100vh;
    /* background-image: url("./img/background.png"); */
    /* background-color:  rgb(20, 82, 101,1); */
    /* background-repeat: no-repeat;
    background-size: cover; */
    z-index: 1;
  }
  .react-multiple-carousel__arrow--left {
    left: -1rem !important;
    bottom: 1rem;
  }
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
    bottom: 1rem;
  }
  .before-card {
    margin-left: -1rem;
    margin-top: 1.5rem;
  }

  .col-works-home {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .servicecol-descript {
    margin: 3rem 6rem !important;
  }
  .explore-row {
    left: 47%;
  }
  .sidebar {
    max-width: 18%;
    margin-left: 0.6rem;
  }
}
@media (min-width: 1500px) {
  .network-header {
    margin-left: -10.5rem !important;
  }
  .servicecol-descript {
    margin: 3rem 10rem !important;
  }
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 800, 300);
  }
}
@media (width: 1024px) and (height: 600px) {
}
@media (min-width: 1600px) {
  .intro-text-home {
    text-align: center !important;

    font-size: 2.4rem;
  }
}

/*
svg positioning
*/

@media (min-width: 100px) and (max-width: 400px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 5, 200);
  }
}
@media (min-width: 401px) and (max-width: 764px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 25, 200);
  }
}
@media (min-width: 764px) and (max-width: 990px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 190, 250);
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 300, 320);
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 475, 130);
  }
}
@media (min-width: 1500px) and (max-width: 1800px) {
  #svg_16 {
    transform: matrix(1.2, 0, 0, 1.2, 550, 350);
  }
}
